import Model from "../Model";

class Summary extends Model {
    async students(params){
        return await this.request({
            method: 'POST',
            url: 'summary/students',
            data: params
        });
    }

    async visits(params){
        return await this.request({
            method: 'POST',
            url: 'summary/visits',
            data: params
        });
    }
}

export default new Summary;